let shadowRoot;

window.addEventListener('heyflow-screen-view', (event) => {
	if (event.detail.screenName === 'co-applicant-name') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let fname = shadowRoot.querySelector(
			'input[data-variable="co-applicant-first-name"]',
		);

		if (fname.value === 'n/a') {
			fname.value = '';
		}

		let lname = shadowRoot.querySelector(
			'input[data-variable="co-applicant-last-name"]',
		);

		if (lname.value === 'n/a') {
			lname.value = '';
		}
	}
	if (event.detail.screenName === 'co-applicant-date-of-birth') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let cdateofbirth = shadowRoot.querySelector(
			'input[data-variable="co-applicant-date-of-birth"]',
		);
		console.log(cdateofbirth.value);
		if (cdateofbirth.value === '') {
			cdateofbirth.value = '1990-01-01';
		}
	}
	if (event.detail.screenName === 'co-applicant-phone-number') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let cphonenumber = shadowRoot.querySelector(
			'input[data-variable="co-applicant-phone-number"]',
		);
		console.log('phoneno====', cphonenumber.value);
		if (cphonenumber.value === '-') {
			cphonenumber.value = '';
		}

		let cemail = shadowRoot.querySelector(
			'input[data-variable="co-applicant-email1"]',
		);

		if (cemail.value === 'johndoe@mail.com') {
			cemail.value = '';
		}
	}
	if (event.detail.screenName === 'co-applicant-employer') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let cemployer = shadowRoot.querySelector(
			'input[data-variable="co-applicant-employer"]',
		);

		if (cemployer.value === 'n/a') {
			cemployer.value = '';
		}

		let cposition = shadowRoot.querySelector(
			'input[data-variable="co-applicant-position"]',
		);

		if (cposition.value === 'n/a') {
			cposition.value = '';
		}
	}
	if (event.detail.screenName === 'co-applicant-annual-income') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let cannualincome = shadowRoot.querySelector(
			'input[data-variable="co-applicant-annual-income"]',
		);

		if (cannualincome.value === '0') {
			cannualincome.value = '';
		}
	}
	if (event.detail.screenName === 'residential-company-info') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let employer = shadowRoot.querySelector(
			'input[data-variable="employer-name"]',
		);

		if (employer.value === 'n/a') {
			employer.value = '';
		}

		let position = shadowRoot.querySelector('input[data-variable="job-title"]');

		if (position.value === 'n/a') {
			position.value = '';
		}
	}
	if (event.detail.screenName === 'residential-yes-address') {
		shadowRoot =
			(document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
				document.querySelector(`[flow-id="${event.detail.flowID}"]`)
					.shadowRoot) ||
			document;
		let street = shadowRoot.querySelector('input[data-variable="street_1"]');

		if (street.value === 'n/a') {
			street.value = '';
		}

		let house1 = shadowRoot.querySelector('input[data-variable="house_1"]');

		if (house1.value === 'n/a') {
			house1.value = '';
		}

		let city = shadowRoot.querySelector('input[data-variable="province_1"]');

		if (city.value === 'n/a') {
			city.value = '';
		}

		let province = shadowRoot.querySelector('input[data-variable="city_1"]');

		if (province.value === 'Ontario') {
			province.value = '';
		}

		let postal = shadowRoot.querySelector(
			'input[data-variable="postal-code_1"]',
		);

		if (postal.value === 'A1A 1A1') {
			postal.value = '';
		}
	}
});

function fetchIPAddress() {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const ipAddress = data.ip;
                // Update the input field with the fetched IP address
                document.querySelector('input[data-variable="ipAddress"]').value = ipAddress;
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }
window.addEventListener('heyflow-init', (event) => {
    fetchIPAddress();
});
